import React from 'react';
import './home.css';
import './delete_account.css';

import FadeInSection from '../animation/fadein_onscroll';

document.querySelectorAll('a[data-scroll]').forEach(anchor => {
  anchor.addEventListener('click', function (e) {
    e.preventDefault();

    document.querySelector(this.getAttribute('href')).scrollIntoView({
      behavior: 'smooth'
    });
  });
});

function DeleteAccount() {
  return (
    <div className="subpage-padding" id='start'>
      <FadeInSection>
        <h1>Radera ditt konto</h1>
      </FadeInSection>
      <div className="max-width">
        <FadeInSection>
          <p>
            För att ta bort ditt konto hos Quality Food Control, vänligen följ stegen nedan:
          </p>
          <ol className="ol">
            <li>Skicka oss ett e-postmeddelande från din registrerade e-postadress.</li>
            <li>I meddelandet, ange att du önskar att ta bort ditt konto.</li>
            <li>Vi återkommer till dig så snart som möjligt för att bekräfta borttagningen.</li>
          </ol>
          <br />
          <br />
          <a 
            href="mailto:contact@qualityfoodcontrol.com?subject=Jag%20vill%20ta%20bort%20mitt%20QFC-konto&body=Hej,%20jag%20vill%20ta%20bort%20mitt%20QFC-konto.%20%0A%0A%20{Ange%20din%20e-postadress%20här}"
            className="button-primary large"
          >
            Jag vill radera mitt konto
          </a>
        </FadeInSection>
      </div>
    </div>
  );
}

export default DeleteAccount;