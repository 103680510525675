import React from 'react';
import './home.css';
import './terms.css';

import FadeInSection from '../animation/fadein_onscroll';

document.querySelectorAll('a[data-scroll]').forEach(anchor => {
  anchor.addEventListener('click', function (e) {
    e.preventDefault();

    document.querySelector(this.getAttribute('href')).scrollIntoView({
      behavior: 'smooth'
    });
  });
});

function TermsEN() {
  return (
    <div className="subpage-padding" id='start'>
      <FadeInSection>
        <h1>Terms of Service</h1>
      </FadeInSection>
      <br />
      <div className="max-width">
        <FadeInSection>
            <h3>1. Introduction</h3>
            <h4>1.1 Overview and Purpose:</h4>
            <p>TQM Sweden AB offers services aimed at helping companies specializing in food to meet food safety requirements and ensure that documents associated with food safety comply with applicable laws and regulations. This agreement stipulates the terms of use of our services and the platform "Quality Food Control" administered by "TQM Sweden AB" (hereinafter "TQM").</p>
            <h4>1.2 Acceptance:</h4>
            <p>By using TQM's services, the Customer accepts the terms in this document as well as the annexes Data Processing Agreement and Privacy Policy. By accessing the services, the Customer has accepted responsibility for staying updated on the contents of the agreement and the annexes applicable between TQM and the Customer. These general terms, any service orders including pricing plan as stated on the website or in a separate quote, and the aforementioned annexes are collectively referred to as the "Agreement."</p>
        </FadeInSection>
        <br />
        <br />
        <FadeInSection>
            <h3>2. Definitions</h3>
            <h4>2.1 Definitions:</h4>
            <p>The term "Agreement" is defined under "Acceptance" in section 1.2.</p>
            <p>The term "Customer" refers to the legal entity that has entered into an agreement with TQM and thereby accepted the Agreement and its contents in full.</p>
            <p>The term "Parties" refers to both the Customer and TQM Sweden AB collectively.</p>
            <p>The term "Services" refers to the services provided by TQM Sweden AB that the Customer utilizes through the contractual relationship. The Services mainly include quality management, HACCP system, supplier management, self-monitoring, document control, file management, AI review of routines and risks, and all other services that the Customer may use on the Quality Food Control platform.</p>
        </FadeInSection>
        <br />
        <br />
        <FadeInSection>
            <h3>3. Contract Duration</h3>
            <p>The Agreement is valid in accordance with the pricing plan chosen by the Customer or as per the quote agreed upon by TQM and the Customer.</p>
        </FadeInSection>
        <br />
        <br />
        <FadeInSection>
            <h3>4. Terms of Use</h3>
            <h4>4.1 Access and Usage:</h4>
            <p>Conditions and limitations apply for access and use of the service, and the Customer must comply with these in order to retain access to the service.</p>
            <h4>4.2 Tracking and Analytics Tools:</h4>
            <p>The Customer agrees to necessary tracking via Google Analytics for TQM to manage volumes and ensure product functionality.</p>
            <h4>4.3 User Conduct:</h4>
            <p>The Customer agrees to use the services in compliance with applicable laws and regulations and in accordance with the rights and obligations under the Agreement. The Customer is fully responsible for the data that the Customer and its users produce and process using the services. The Customer is liable for breaches of the Agreement caused by the Customer's users. Users of the Customer include, but are not limited to, employees, representatives, agents, and consultants.</p>
            <p>The Agreement grants the Customer a non-exclusive, non-transferable right to access and use the specified Services in accordance with the terms of this Agreement. The Customer does not obtain any other rights to the software or other elements used by TQM to provide the Services.</p>
            <p>The Customer assures that only personal data required for using the Services will be transferred. The Customer is responsible for not transferring any personal data that is not in compliance with GDPR or that is unnecessary for utilizing the services. Should the Customer fail in this regard, the Customer understands that TQM will not be considered as a Data Processor or Data Controller.</p>
            <p>The Customer acknowledges that TQM has a support account, used to assist and support the Customer. Through this, TQM has full access to the Customer's account and its contents. TQM also has the ability to make changes via said account but will do so only upon the Customer's instruction. The Customer can disable the support account in their settings, but this will also prevent TQM from providing support through this account.</p>
        </FadeInSection>
        <br />
        <br />
        <FadeInSection>
            <h3>5. Payment Terms</h3>
            <h4>5.1 Prices:</h4>
            <p>All prices for TQM's services are set forth in the Customer's pricing plan and apply between TQM and the Customer unless otherwise agreed by the Parties.</p>
            <h4>5.1.1 Payment Terms:</h4>
            <p>Each payment under this Agreement shall be made 30 days after the invoice date. The fee for the Service will be invoiced in advance at the beginning of each Billing Period. In case of late payment, TQM has the right to charge interest on overdue payments according to the Swedish Interest Act (1975: 365), calculated from the due date until payment is made. Additionally, TQM may, after sending a reminder to the Customer, suspend the Customer's access to the Services until all outstanding amounts are received by TQM.</p>
            <h4>5.1.2 Miscellaneous:</h4>
            <p>All prices are exclusive of VAT and any other taxes and fees unless otherwise stated.</p>
            <h4>5.2 Right of Withdrawal:</h4>
            <p>TQM does not offer any right of withdrawal after the purchase has been completed or after an account has been registered.</p>
            <h4>5.3 Termination of Agreement:</h4>
            <p>Either Party may terminate the Agreement between TQM and the Customer at any time. The Customer has the right to use their subscription during the Agreement period and is obliged to pay for the entire duration of the Agreement irrespective of a termination.</p>
            <p>Agreements that do not have a fixed term have a notice period of one calendar month. Termination must be in writing.</p>
            <p>Agreements with a fixed term must be terminated in writing at least one calendar month before the end of the Agreement period; otherwise, the Agreement will be renewed for the same binding period as the original Agreement.</p>
            <h4>5.4 Price changes:</h4>
            <p>TQM reserves the right to adjust the prices for our services once per year. Any price changes will be based on factors such as inflation, consumer price index, or other cost increases incurred by TQM.</p>
            <p>The Customer will be informed of any price changes in advance, at least 30 days before the price change takes effect.</p>
            <p>If the Customer has an Agreement with a fixed term, the agreed price applies for the entire period of the Agreement and is not subject to such increases.</p>
        </FadeInSection>
        <br />
        <br />
        <FadeInSection>
            <h3>6. Data Protection and Confidentiality</h3>
            <h4>6.1 Data Protection:</h4>
            <p>TQM ensures that personal data is processed in accordance with GDPR and other applicable laws and regulations.</p>
            <h4>6.2 Privacy Policy:</h4>
            <p>TQM's privacy policy is available on our website and describes how we manage and protect users' personal data.</p>
        </FadeInSection>
        <br />
        <br />
        <FadeInSection>
            <h3>7. Intellectual Property Rights</h3>
            <h4>7.1 Ownership:</h4>
            <p>All intellectual property rights, including but not limited to patents, trademarks, source code, and copyrights, are owned by TQM Sweden AB.</p>
            <h4>7.1.1:</h4>
            <p>The Customer is granted the right to use TQM's solution during the Agreement period. The Customer understands and accepts that all intellectual property rights to the SaaS solution are owned by and belong to TQM. The Customer is not entitled to any software or other subscription services beyond what is stated in this Agreement and the service selected by the Customer under their subscription plan.</p>
            <p>TQM has the right to use and share customer information and data for the purpose of providing the services, which may include, but is not limited to, sharing general certificates within the SaaS solution provided by TQM. TQM will never share sensitive corporate information and always acts in accordance with applicable laws and regulations. All information provided by the Customer is owned by the Customer.</p>
            <p>If TQM is accused of infringing third-party intellectual property rights, TQM will defend the Customer against such allegations at TQM's expense. It is the Customer's responsibility to inform TQM of such allegations within 48 hours from the time the Customer receives this information from a third party. Furthermore, the Customer shall assist TQM with information and similar resources in defending against such allegations to a reasonable extent without requesting compensation.</p>
        </FadeInSection>
        <br />
        <br />
        <FadeInSection>
            <h3>8. Limitation of Liability and Warranties</h3>
            <h4>8.1 Limitation of Liability:</h4>
            <p>The Customer has the right to claim compensation for documented direct loss suffered due to TQM's breach of the Agreement. TQM is not liable for indirect damages or consequential damages such as lost profit, losses caused by delayed start or production interruption, or losses due to lost data or claims from third parties. Each Party's liability for damages is limited to the amount paid by the Customer to TQM over a period of twelve months, excluding VAT.
Compensation for damages must be proven by the Party claiming that damage has occurred.</p>
        </FadeInSection>
        <br />
        <br />
        <FadeInSection>
            <h3>9. Force Majeure</h3>
            <p>If the performance of the Agreement becomes impossible or significantly more complicated due to force majeure, from circumstances that the Party could not control and that the Party could not reasonably have anticipated, and whose effects the Party could not reasonably have avoided or overcome, such a Party shall be allowed to delay the performance of the obligation without penalty; provided that the Party promptly informs the other Party in writing. Force majeure shall include war, acts of war, governmental actions, new or amended legislation, labor disputes, breakdown of telecommunications, and other similar circumstances.</p>
        </FadeInSection>
        <br />
        <br />
        <FadeInSection>
            <h3>10. Miscellaneous</h3>
            <h4>10.1 Amendments to the Agreement:</h4>
            <p>TQM reserves the right to amend this Agreement at any time, and it is the Customer's responsibility to regularly check for changes to the Agreement.</p>
            <h4>10.2 Use of Customer's Name and Logo:</h4>
            <p>TQM has the right to use the Customer's name and logo in sales presentations, offers, and on TQM's websites.</p>
            <h4>10.3</h4>
            <p>TQM may assign its rights and/or obligations under this Agreement in whole or in part as long as such transfer does not substantially hinder the fulfillment of the Agreement. TQM shall inform the Customer within a reasonable time after such a transfer.</p>
            <h4>10.4</h4>
            <p>The Customer does not have the right to assign their rights or obligations under the Agreement without written permission from TQM. If TQM allows such an assignment, TQM has the right to charge for the costs incurred by such an assignment.</p>
        </FadeInSection>
        <br />
        <br />
        <FadeInSection>
            <h3>11. Confidentiality</h3>
            <h4>11.1 Confidential Information:</h4>
            <p>Confidential Information under this Agreement shall refer to all information, regardless of whether it is oral or written, in electronic or any other form.</p>
            <h4>11.2</h4>
            <p>Both TQM and the Customer shall treat all Confidential Information received from the other Party as confidential. The Parties to the Agreement may not use such Confidential Information except as provided herein and shall make reasonable efforts not to disclose such Confidential Information to any third party. Such Confidential Information includes trade secrets, source code, information not considered public, and any information that could pose a risk or harm if disclosed to third parties or the public. TQM and the Customer accept that they will handle such information with the utmost care and judgment.</p>
            <p>Each Party agrees to make Confidential Information available only on a "need-to-know" basis and only to its employees or others who have a reasonable need to know the information. Each Party shall promptly notify the other Party if there has been actual or suspected misuse or unauthorized disclosure of the other Party's Confidential Information.</p>
            <h4>11.3</h4>
            <p>Notwithstanding the above, neither TQM nor the Customer shall have any liability towards the other Party if confidential information has been disclosed, provided that the disclosing Party can prove that such information: (i) was publicly known at the time of disclosure or became publicly known without the disclosing Party's actions; (ii) was known to the receiving Party without restrictions at the time of disclosure, as evidenced by documents existing at the time of disclosure; (iii) was disclosed with prior approval from the disclosing Party; (iv) was developed by the receiving Party independently and without the use of the other Party's Confidential Information, as evidenced by files created at the time of such independent development; or (v) must be disclosed due to mandatory legislation or pursuant to a court order, administrative authority, or other governmental authority decision, provided that the receiving Party promptly notifies the disclosing Party, allowing the disclosing Party to take action in court or otherwise prevent or limit such disclosure.</p>
        </FadeInSection>
        <br />
        <br />
        <FadeInSection>
            <h3>12. Governing Law</h3>
            <p>This Agreement shall be governed by and construed in accordance with Swedish law.</p>
            <h4>12.1 Dispute Resolution:</h4>
            <p>Disputes arising in connection with this Agreement shall primarily be resolved through negotiations, and if an agreement cannot be reached, the dispute shall be exclusively settled by Stockholm District Court. Each Party has the right to appeal a possible judgment to both the Svea Court of Appeal and the Supreme Court.</p>
            <h4>12.2 Contact Information:</h4>
            <p>For questions regarding this Agreement, please contact TQM via <a href="mailto:contact@qualityfoodcontrol.com">contact@qualityfoodcontrol.com</a>.</p>
        </FadeInSection>
        <br />
        <br />
        <FadeInSection>
            <h3>13. Approval</h3>
            <p>The Customer has accepted the Agreement when they agreed to become a customer or when they gained access to the service(s) offered by TQM in accordance with this Agreement. The earliest occurrence shall be considered as the effective date of the Agreement.</p>
        </FadeInSection>
        <br />
        <br />
      </div>
    </div>
  );
}

export default TermsEN;