import React from 'react';
import './home.css';
import './privacy.css';

import FadeInSection from '../animation/fadein_onscroll';

document.querySelectorAll('a[data-scroll]').forEach(anchor => {
  anchor.addEventListener('click', function (e) {
    e.preventDefault();

    document.querySelector(this.getAttribute('href')).scrollIntoView({
      behavior: 'smooth'
    });
  });
});

function Terms() {
  return (
    <div className="subpage-padding" id='start'>
      <FadeInSection>
        <h1>Integritetspolicy</h1>
        <br />
        <p>Giltig från: 2023-09-21</p>
      </FadeInSection>
      <br />
      <div className="max-width">
        <FadeInSection>
          <p>TQM Sweden AB, organisationsnummer 559280-3042, beläget på Parkvägen 2, 169 35 Stockholm, Sverige (hädanefter "TQM“) administrerar plattformen på https://www.qualityfoodcontrol.com, som skapar en digital arbetsyta för juridiska enheter specialiserade på livsmedel.</p>
          <p>TQM plattform och de tjänster den tillhandahåller är avsedda enbart för företagskunder, och därför kommer TQM endast att behandla personuppgifter av representanter som agerar på uppdrag av företagskunder i den mån det är nödvändigt för att tillhandahålla plattformstjänsten till företagskunder. Med det sagt, termen “Användare“ i TQMs integritetspolicy ska referera till en representant som agerar på uppdrag av en företagskund.</p>
          <p>TQM garanterar att det följer kraven som uppstår från den allmänna dataskyddsförordningen 2016/679 vid behandling av data. Med denna integritetspolicy, avser TQM att informera Användare om de behandlingsoperationer som deras data utsätts för.</p>
          <p>Vi har rätten att ändra denna integritetspolicy ensidigt när som helst. I händelse av en sådan förändring, kommer vi att meddela Användarna.</p>
        </ FadeInSection>
        <br />
        <br />
        <FadeInSection>
          <h3>1. Insamlad data</h3>
          <p>TQM ska behandla data om Användares som har frivilligt lämnats till TQM-plattformen. Användares förstår att inlämnandet av data till TQM är frivilligt, men de kommer inte att kunna använda TQM-plattformen och de tjänster den tillhandahåller om inte data lämnas in.</p>
          <p>TQM ska samla in och behandla följande data om Användares (hädanefter kollektivt benämnt som "Personuppgifter"):</p>
          <p>namn, telefonnummer och e-postadress;</p>
          <p>information om den juridiska enheten som representeras (företagsnamn, registerkod);</p>
          <p>Kreditkortsinformation för betalning av tjänsten;</p>
          <p>Annan data som Användaren väljer att dela med sig om sig själv på plattformen.</p>
        </ FadeInSection>
        <br />
        <br />
        <FadeInSection>
          <h3>2. Syfte & rättslig grund</h3>
          <p>TQM ska behandla Personuppgifter enbart för de ändamål som fastställts av lagen och denna Integritetspolicy, inklusive följande ändamål: att skapa ett personligt konto för Användaren, identifiera och kontakta Användaren, tillhandahålla plattformstjänsten, tillhandahålla supporttjänster (inklusive användarstöd) och uppfylla förpliktelser som uppstår från lagen.</p>
          <p>TQM ska behandla Användares’ personuppgifter på grundval av följande:</p>
          <p>Det rättsliga avtalet mellan den juridiska enheten representerad av Användaren och TQM för att tillhandahålla plattformstjänsten till den, vilket också inkluderar att ge tillgång till plattformen;</p>
          <p>Samtycket som Användaren gett till TQM. På grundval av detta samtycke kommer TQM att behandla sådana data som inte är nödvändiga för att tillhandahålla plattformstjänsten i händelse av att Användaren ändå har valt att frivilligt lämna dem på plattformen (till exempel profilbilden);</p>
          <p>För att uppfylla relevanta lagliga skyldigheter.</p>
        </ FadeInSection>
        <br />
        <br />
        <FadeInSection>
          <h3>3. Dataöverföring & lagring</h3>
          <p>TQM kan vidarebefordra Användares personuppgifter till tredje parter, till exempel revisorer, juridiska tjänsteleverantörer, eller andra personer som tillhandahåller tjänster till TQM (till exempel en molntjänstleverantör eller livsmedelsteknolog). TQM har gjort allt för att ovanstående tredje parter ska säkerställa konfidentialiteten och säkerheten för personuppgifterna.</p>
          <p>De tredje parter till vilka TQM vidarebefordrar Användares personuppgifter kan vara belägna utanför Europeiska ekonomiska samarbetsområdet där andra dataskyddsregler kan gälla och där Europeiska kommissionen inte har genomfört ett beslut om adekvat skydd. I dessa länder kan säkerheten för personuppgifter (inklusive skydd mot missbruk, obehörig åtkomst, offentliggörande, ändring eller förstörelse) inte garanteras på samma nivå som inom Europeiska unionen. Vid vidarebefordran av personuppgifterna utanför Europeiska ekonomiska samarbetsområdet garanterar TQM att lämpliga säkerhetsåtgärder vidtas. Om Användaren önskar få en kopia av sådana uppgifter, ber vi dig meddela TQM därefter.</p>
          <p>TQM har genomfört nödvändiga organisatoriska, fysiska och IT-säkerhetsåtgärder för att de uppgifter som publiceras på plattformen, inklusive personuppgifterna, ska skyddas från alla former av missbruk, obehörig åtkomst, offentliggörande, ändring eller förstörelse. Tredje parter har informerats om att de endast får använda Användares personuppgifter för det ändamål och i den omfattning som anges av TQM.</p>
          <p>TQM kommer att lagra personuppgifterna så länge som det föreskrivs av lagen men inte längre än vad TQM rimligen behöver för att uppfylla de syften för vilka uppgifterna samlades in eller behandlades; bland annat kan lagring av personuppgifterna tills eventuella krav från Användaren löper ut vara ett av dessa kriterier.</p>
          <p>TQM ska vidta rimliga åtgärder för att säkerställa att personuppgifterna är korrekta och pålitliga.</p>
        </FadeInSection>
        <br />
        <br />
        <FadeInSection>
          <h3>4. Användarens rättigheter avseende datainsamling</h3>
          <p>Användaren har följande rättigheter gällande datainsamling:</p>
          <p>att begära åtkomst till de personuppgifter om Användaren som samlats in av TQM och att begära en kopia av dessa personuppgifter;</p>
          <p>att begära rättelse, ändring eller radering av personuppgifterna om de är felaktiga eller inte behandlas i enlighet med gällande krav;</p>
          <p>i de fall som fastställts av lag, kräva att TQM begränsar insamlingen, behandlingen eller användningen av personuppgifterna om Användaren;</p>
          <p>i händelse av överträdelse av Användarens rättigheter, lämna in ett klagomål till Integritetsskyddsmyndigheten (Datainspektionen).</p>
        </FadeInSection>
        <br />
        <br />
        <FadeInSection>
          <h3>5. Cookies</h3>
          <p>På sin plattform använder TQM kakor (cookies) och andra spårningsverktyg för att förbättra användarupplevelsen på TQM-plattformen, vilket säkerställer hög kvalitet på den tjänst som tillhandahålls och användarvänlig navigering på plattformen.</p>
          <p>Kakor är i huvudsak textfiler som sparas på Användarens dator, smartphone eller andra enheter.</p>
          <p>De kakor som TQM använder på sin plattform kan delas in på följande sätt beroende på när de placeras:</p>
          <p>Tillfälliga eller sessionskakor går ut när Användaren lämnar plattformen eller stänger webbläsaren. TQM använder sessionskakor för att vissa funktioner på plattformen ska fungera (till exempel inloggning).</p>
          <p>Permanenta kakor lagras permanent på Användarens enhet under den tid som anges i den permanenta kakan och aktiveras när Användaren besöker plattformen vars kaka har installerats. Syftet med permanenta kakor är att komma ihåg Användarens inställningar på TQM-plattformen. Till exempel använder TQM permanenta kakor för att komma ihåg språkinställningar eller spara information om företaget och dess verksamhetsort samt dess autentiseringskod.</p>
          <p>TQM använder också följande typ av kakor på sin plattform, som kan särskiljas efter syftet med deras placering:</p>
          <p>Nödvändiga kakor är viktiga för att navigera på webbsidan, använda dess funktioner och tillhandahålla de tjänster som Användare har valt. Webbplatsen kan inte tillhandahållas och begärda tjänster kan inte tillhandahållas till Användaren utan installation av sådana kakor;</p>
          <p>Preferenskakor gör det möjligt att komma ihåg de val Användaren har gjort (till exempel språkval) för att skapa en mer personlig och bekväm användarupplevelse.</p>
          <p>För att förhindra missbruk av plattformen kan TQM använda kakor som samlar in data om de enheter och program som används för plattformen.</p>
          <p>Användaren kan när som helst radera kakor eller blockera dem med hjälp av webbläsarinställningarna; dock kan vissa kakor vara nödvändiga för att tjänsten som plattformen tillhandahåller ska fungera. I det avseendet förstår Användaren att vissa funktioner på plattformen kanske inte fungerar korrekt om kakor raderas eller blockeras.</p>
          <p>Följande typer av kakor används på webbplatsen:</p>
          <p>Strikt nödvändiga/essentiella kakor. Dessa kakor är nödvändiga för att användaren ska kunna röra sig runt på webbplatsen och använda dess funktioner, som att komma åt säkra områden på webbplatsen. Utan dessa kakor kan inte de tjänster användaren har begärt tillhandahållas. Dessa kakor samlar inte in information som identifierar en besökare.</p>
          <p>Prestandakakor. Dessa kakor samlar in information om hur besökare använder en webbplats, till exempel vilka sidor besökarna oftast går till och om de får felmeddelanden från webbsidor. Dessa kakor samlar inte in information som identifierar en besökare. All information som dessa kakor samlar in är aggregerad och därför anonym. Den används endast för att förbättra hur en webbplats fungerar.</p>
          <p>Funktionalitetskakor. Dessa kakor låter webbplatsen komma ihåg val användaren gör (som användarens användarnamn, språk eller den region användaren befinner dig i) och ger förbättrade, mer personliga funktioner. De kan också användas för att tillhandahålla tjänster användaren har begärt, som att titta på en video eller kommentera en blogg. Informationen som dessa kakor samlar in kan vara anonymiserad, och de kan inte spåra din surfaktivitet på andra webbplatser.</p>
          <p>Beteendestyrd reklam och meddelandekakor. Dessa kakor används för att leverera annonser och meddelanden som är mer relevanta för dig och dina intressen. De används också för att begränsa antalet gånger användaren ser en annons eller meddelande samt hjälpa till att mäta effektiviteten i reklam- och meddelandekampanjer. De kommer ihåg att användaren har besökt en webbplats och denna information delas med andra organisationer, som annonsörer eller meddelandetjänstleverantörer. Ganska ofta kommer målinriktade eller reklamkakor att vara länkade till webbplatsfunktionalitet som tillhandahålls av den andra organisationen.</p>
          <p>Ytterligare information om användningen av kakor samt instruktioner för att inaktivera kakor finns tillgängliga på webbsidan.</p>
        </FadeInSection>
        <br />
        <br />
        <FadeInSection>
          <h3>6. Kontaktinformation</h3>
          <p>Om Användaren önskar utöva någon rättighet fastställd i avsnitt 4 av Integritetspolicyn eller få ytterligare information om behandlingen av dess rättigheter, vänligen kontakta TQM via e-postadress: <a class="link" href="mailto:contact@qualityfoodcontrol.com">contact@qualityfoodcontrol.com</a>.</p>
        </FadeInSection>

        <br />
        <br />
        <br />
        <br />

        <FadeInSection>
          <h2>Personuppgiftsbiträdesavtal</h2>
        </FadeInSection>
        <br />
        <FadeInSection>
          <h3>1. Bakgrund och omfattning</h3>
          <p>Parterna har träffat avtal i samband med att Personuppgiftsansvarige har fått tillgång till tjänsterna varigenom Personuppgiftsbiträdets har åtagit sig att för Personuppgiftsansvarigs räkning utföra en eller flera tjänster vilka regleras inom ramen för detta Personuppgiftsavtal samt andra villkor vilka gemensamt utgör ”Avtalet”. Vid utförandet av tjänsterna enligt Avtalet kommer Personuppgiftsbiträdets att behandla personuppgifter för Personuppgiftsansvariges räkning. Personuppgiftsbiträdets kommer därmed att vid de aktuella tjänsternas utförande att agera som Personuppgiftsbiträde åt Personuppgiftsansvarig som är ansvarig för de personuppgifter som ska behandlas.</p>
        </ FadeInSection>
        <br />
        <br />
        <FadeInSection>
          <h3>2. Ändamål med behandling av personuppgifterna</h3>
          <p>Personuppgiftsbiträdets får endast behandla personuppgifter för de ändamål som uppgetts i Avtalet eller i skriftligt tilläggsavtal som hänvisar till detta avtal och inte för något annat ändamål än vad som är nödvändigt för fullgörande av Avtalet.</p>
        </ FadeInSection>
        <br />
        <br />
        <FadeInSection>
          <h3>3. Underleverantör</h3>
          <p>Personuppgiftsbiträdet får inte utan beställarens medgivande lämna personuppgifter för behandling hos underleverantör s.k. underbiträde. Om ett allmänt medgivande lämnas ska Personuppgiftsbiträdet informera Personuppgiftsbiträdet om eventuella planer på att anlita nya underbiträden eller byta underbiträde. Personuppgiftsansvarig ska snarast göra invändningar mot sådana förändringar, dock senast inom 2 veckor från Personuppgiftsbiträdet meddelat att förändringen kommer att ske.</p>
          <p>Invänder Personuppgiftsansvarig mot förändringarna får personuppgifterna inte överlämnas och tjänsterna ska utföras av Personuppgiftsbiträdet i egen regi alternativt av tidigare godkänt underbiträde.</p>
          <p>Personuppgiftsbiträdets ansvarar för att skriftliga avtal ingås med underleverantörer.</p>
        </ FadeInSection>
        <br />
        <br />
        <FadeInSection>
          <h3>4. Villkor för behandlingen av personuppgifter</h3>
          <p>För Personuppgiftsbiträdets behandling gäller följande. Personuppgiftsbiträdet</p>
          <ul className="alpha-list">
            <li>får endast behandla personuppgifter efter dokumenterade instruktion från den Personuppgiftsansvarige, inbegripet när det gäller överföringar av personuppgifter till ett tredje land eller en internationell organisation, såvida inte denna behandling krävs enligt unionsrätten eller enligt en medlemsstats nationella rätt som Personuppgiftsbiträdet omfattas av. I sådant fall ska Personuppgiftsbiträdet informera den Personuppgiftsansvarige om det rättsliga kravet innan uppgifterna behandlas, såvida sådan information inte är förbjuden med hänvisning till ett viktigt allmänintresse enligt denna rätt,</li>
            <li>ska säkerställa att personer med behörighet att behandla personuppgifterna har åtagit sig att iaktta konfidentialitet eller omfattas av en lämplig lagstadgad tystnadsplikt,</li>
            <li>ska vidta alla åtgärder för säkerhet i samband med behandlingen av personuppgifterna enligt art 32 i dataskyddsförordningen</li>
            <li>ska respektera de villkor som gäller för anlitande av underbiträde enligt p 3 ovan</li>
            <li>ska med tanke på behandlingens art, hjälpa den Personuppgiftsansvarige genom lämpliga tekniska och organisatoriska åtgärder, i den mån detta är möjligt, så att den Personuppgiftsansvarige kan fullgöra sin skyldighet att svara på begäran om utövande av den registrerades rättigheter i enligt helt med kapitel III i dataskyddsförordningen,</li>
            <li>ska bistå den Personuppgiftsansvarige med att se till att skyldigheterna enligt art 32-36 i dataskyddsförordningen fullgörs (angående information till den registrerade om en personuppgiftsincident, anmälan av en personuppgiftsincident till tillsynsmyndigheten och information till den registrerade om en personuppgiftsincident), med beaktande av typen av behandling och den information som personuppgiftbiträdet har att tillgå,</li>
            <li>beroende på vad den Personuppgiftsansvarige väljer, ska radera eller återlämna samtliga personuppgifter till den Personuppgiftsansvarige efter det att tillhandahållandet av behandlingstjänsterna har avslutats, och radera befintliga kopior såvida inte lagring av personuppgifterna krävs enligt unionsrätten eller nationell rätt, och</li>
            <li>ska ge den Personuppgiftsansvarige tillgång till all information som krävs för att visa att de skyldigheter som fastställs i denna artikel har fullgjorts samt möjliggöra och bidra till granskningar, inbegripet inspektioner, som genomförs av den Personuppgiftsansvarige eller av en annan revisor som bemyndigats av den Personuppgiftsansvarige.</li>
          </ul>
          <p>Vidare förbinder sig Personuppgiftsbiträdets att föra register över behandlingarna och samarbeta med tillsynsmyndigheten och göra detta register tillgängligt för tillsynsmyndigheten.</p>
          <p>Personuppgiftsbiträdet ska vid behov och på begäran bistå den Personuppgiftsansvarige med fullgörande av de skyldigheter som härrör från utförandet av konsekvensbedömningar avseende dataskydd och förhandssamråd med tillsynsmyndigheten.</p>
        </ FadeInSection>
        <br />
        <br />
        <FadeInSection>
          <h3>5. Säkerhetsåtgärder</h3>
          <p>Personuppgiftsbiträdet ska begränsa åtkomsten till personuppgifter till personer som behöver sådan åtkomst för att fullgöra sina arbetsuppgifter.</p>
          <p>Personuppgiftsbiträdet ska se till att personuppgifter inte behandlas i strid med bestämmelserna i vid var tid gällande lagstiftning m.m. gällande dataskydd för personuppgifter såsom t.ex. Dataskyddsförordningen och Datainspektionens föreskrifter. Personuppgiftsbiträdet ska vidta lämpliga tekniska och organisatoriska åtgärder för att skydda personuppgifter från obehörig åtkomst, förstörelse och ändring.</p>
          <p>Personuppgiftsbiträdet förbinder sig att omedelbart informera den Personuppgiftsansvarige om en instruktion strider mot dataskyddförordningen eller mot andra bestämmelser avseende dataskydd för personuppgifter.</p>
          <p>Personuppgiftsbiträdet och Personuppgiftsansvarig förbinder sig att med beaktande av den senaste utvecklingen, genomförandekostnaderna och behandlingens art, omfattning, sammanhang och ändamål samt riskerna, av varierande sannolikhetsgrad och allvar, för fysiska personers rättigheter och friheter vidta lämpliga tekniska och organisatoriska åtgärder för att säkerställa en säkerhetsnivå som är lämplig i förhållande till risken, inbegripet, när det är lämpligt</p>
          <ul className="alpha-list">
            <li>pseudonymisering och kryptering av personuppgifter,</li>
            <li>förmågan att fortlöpande säkerställa konfidentialitet, integritet, tillgänglighet och motståndskraft hos behandlingsystemen och -tjänsterna,</li>
            <li>förmågan att återställa tillgängligheten och tillgången till personuppgifter i rimlig tid vid en fysisk eller teknisk incident,</li>
            <li>ett förfarande för att regelbundet testa, undersöka och utvärdera effektiviteten hos de tekniska och organisatoriska åtgärder som ska säkerställa behandlingens säkerhet.</li>
          </ul>
          <p>Vid bedömningen av lämplig säkerhetsnivå ska särskild hänsyn tas till de risker som behandling medför, i synnerhet från oavsiktlig eller olaglig förstöring, förlust eller ändring eller till obehörigt röjande av eller obehörig åtkomst till de personuppgifter som överförts, lagrats eller på annat sätt behandlats.</p>
          <p>Personuppgiftsansvarig och Personuppgiftsbiträdet ska vidta åtgärder för att säkerställa att varje fysisk person som utför arbete under den Personuppgiftsansvariges eller Personuppgiftsbiträdets överinseende, och som får tillgång till personuppgifter, endast behandlar dessa på instruktion från Personuppgiftsansvarig, om inte unionsrätten eller medlemsstaternas nationella rätt ålägger honom eller henne att göra det.</p>
        </ FadeInSection>
        <br />
        <br />
        <FadeInSection>
          <h3>6. Personuppgiftsincidenter</h3>
          <p>Personuppgiftsbiträdet ska underrätta Personuppgiftsansvarig utan onödigt dröjsmål efter att ha fått vetskap om en personuppgiftsincident. Underrättelsen ska beskriva personuppgiftsincidentens art, inbegripet, om så är möjligt, de kategorier av och det ungefärliga antalet registrerade som berörs samt de kategorier av och det ungefärliga antalet personuppgiftsposter som berörs. Om och i den utsträckning det inte är möjligt att tillhandahålla informationen samtidigt, får informationen tillhandahållas i omgångar utan onödigt ytterligare dröjsmål.</p>
          <p>Personuppgiftsbiträdet ska vara Personuppgiftsansvarig behjälplig med att dokumentera alla personuppgiftsincidenter, inbegripet omständigheterna kring personuppgiftsincidenten, dess effekter och de korrigerande åtgärder som vidtagits.</p>
        </ FadeInSection>
        <br />
        <br />
        <FadeInSection>
          <h3>7. Ersättning</h3>
          <p>Personuppgiftsbiträdets har rätt att debitera Personuppgiftsansvarig för kostnader som uppstår med anledning av säkerhetsåtgärder och kostnader i samband med personuppgiftsincidenter utöver den ersättning som följer av Avtalet endast i den mån Personuppgiftsansvarig genom oaktsamhet orsakat kostnaderna.</p>
        </ FadeInSection>
        <br />
        <br />
        <FadeInSection>
          <h3>8. Kontakter med tredje man</h3>
          <p>Om tredje man (t.ex. registrerad, myndighet eller någon annan) vänder sig till Personuppgiftsbiträdets med begäran om information om behandling av personuppgifter ska Personuppgiftsbiträdets utan dröjsmål vidarebefordra en sådan begäran till Personuppgiftsansvarig.</p>
          <p>Personuppgiftsbiträdets har inte rätt att företräda Personuppgiftsansvarig gentemot tredje man i fråga om behandling av personuppgifter såvida inte Personuppgiftsansvarig uttryckligen har medgivit detta.</p>
        </ FadeInSection>
        <br />
        <br />
        <FadeInSection>
          <h3>9. Tystnadsplikt</h3>
          <p>Personuppgiftsbiträdets och dessa anställda och underkonsulter har tystnadsplikt för samtliga personuppgifter som behandlas om inget annat är skriftligen avtalat med Personuppgiftsansvarig. Tystnadsplikt råder inte heller gentemot den registrerade eller för uppgifter som är allmänt kända.</p>
        </ FadeInSection>
        <br />
        <br />
        <FadeInSection>
          <h3>10. Immateriella rättighet</h3>
          <p>Samtliga immateriella rättigheter till personuppgifterna innehas av Personuppgiftsansvarig eller den registrerade. Personuppgiftsbiträdet får en icke-exklusiv rätt att använda personuppgifterna och ev. immateriella rättigheter kopplade till dessa enbart för fullgörandet av sina åtaganden enligt Avtalet.</p>
        </ FadeInSection>
        <FadeInSection>
          <h3>11. Ansvar</h3>
          <p>Om en registrerad eller annan tredje man riktar krav mot Personuppgiftsansvarig på grund av Personuppgiftsbiträdets behandling av personuppgifter ska Personuppgiftsbiträdet hålla Personuppgiftsansvarige skadeslös för krav som följer av att Personuppgiftsbiträdet inte följt detta avtal.</p>
          <p>Om en registrerad eller annan tredje man riktar krav mot Personuppgiftsbiträdet på grund av Personuppgiftsansvarigs instruktion om behandling av personuppgifter ska Personuppgiftsansvarig hålla Personuppgiftsbiträdet skadeslös för krav, dock inte om Personuppgiftsbiträdet borde ha uppmärksammat Personuppgiftsansvarig på att behandlingen strider mot gällande regler för dataskydd för behandling av personuppgifter.</p>
          <p>Om flera Personuppgiftsansvariga eller Personuppgiftsbiträden medverkat vid samma behandling, kan varje Personuppgiftsansvarig eller Personuppgiftsbiträde hållas ansvarig för hela skadan. Om de är förenade i samma rättsliga förfarande i enlighet med medlemsstaternas nationella rätt, kan ersättningen dock fördelas i enlighet med varje Personuppgiftsansvarigs eller Personuppgiftsbiträdes ansvar för den genom behandlingen uppkomna skadan, förutsatt att den registrerade som lidit skada tillförsäkras full och effektiv ersättning. Varje Personuppgiftsansvarig eller Personuppgiftsbiträde som har betalat full ersättning får därefter inleda förfaranden för återkrav mot andra Personuppgiftsansvariga eller Personuppgiftsbiträden som medverkat vid samma behandling.</p>
        </ FadeInSection>
        <FadeInSection>
          <h3>12. Radering</h3>
          <p>Personuppgiftsbiträdet ska efter Avtalets upphörande radera samtliga personuppgifter som behandlats för Personuppgiftsansvarig om inte en tidigare tidpunkt överenskoms eller om Personuppgiftsbiträdet varit tvungen att göra det vid tidigare tidpunkt med hänsyn till gällande regelverk. Personuppgiftsbiträdet är i samband med Avtalet upphörande skyldig att återlämna behandlade uppgifter till Personuppgiftsansvarig.</p>
        </ FadeInSection>
        <FadeInSection>
          <h3>13. Ändringar och tillägg</h3>
          <p>Ändringar eller tillägg till detta avtal ska för sin giltighet vara skriftliga och undertecknade av båda parter.</p>
        </ FadeInSection>
        <FadeInSection>
          <h3>14. Avtalstid och upphörande</h3>
          <p>Detta avtal träder i kraft när bägge parter har ingått Avtalet i enlighet med den ordning som anges i de Allmänna villkoren. Parterna samtycker med hänsyn till att det är en SaaS-tjänst att avtalen inte behöver undertecknas för att gälla utan är gällande från det att kunden har a) registrerat sig eller b) fått tillgång till tjänsterna, vilket som sker först, i enlighet med Avtalet. Detta avtal upphör att gälla när Avtalet upphör att gälla. Dock ska punkt 9 fortsätta att gälla i ett år efter att avtalet upphört.</p>
        </ FadeInSection>
      </div>
    </div>
  );
}

// ReactDOM.render(<App />, document.getElementById('root'));

export default Terms;
