import React from 'react';
import './home.css';
import './terms.css';

import FadeInSection from '../animation/fadein_onscroll';

document.querySelectorAll('a[data-scroll]').forEach(anchor => {
  anchor.addEventListener('click', function (e) {
    e.preventDefault();

    document.querySelector(this.getAttribute('href')).scrollIntoView({
      behavior: 'smooth'
    });
  });
});

function Terms() {
  return (
    <div className="subpage-padding" id='start'>
      <FadeInSection>
        <h1>Användarvillkor</h1>
      </FadeInSection>
      <br />
      <div className="max-width">
        <FadeInSection>
            <h3>1. Introduktion</h3>
            <h4>1.1 Översikt och Syfte:</h4>
            <p>TQM Sweden AB erbjuder tjänster som syftar till att hjälpa företag specialiserade på livsmedel att uppfylla krav för livsmedelssäkerhet och säkerställa att dokumenten associerade med livsmedelssäkerhet överensstämmer med tillämpliga lagar och regelverk. Detta avtal stipulerar villkoren för användning av våra tjänster och plattformen “Quality Food Control” som administreras av “TQM Sweden AB” (hädanefter "TQM“).</p>
            <h4>1.2 Acceptans:</h4>
            <p>Genom att använda TQMs tjänster accepterar Kunden villkoren i detta dokument samt bilagorna Personuppgiftsbiträdesavtal och Integritetspolicyn. Kunden har genom tillgång till tjänsterna accepterat att Kunden är ansvarig för att hålla sig uppdaterad på avtalsinnehållet och de bilagor som är gällande mellan TQM och Kunden. Dessa allmänna villkor, eventuella tjänsteorder inkl. prisplan som framgår av hemsidan eller i separat offert, samt ovan nämnda bilagor hänvisas gemensamt till som "Avtalet".</p>
        </FadeInSection>
        <br />
        <br />
        <FadeInSection>
            <h3>2. Definitioner</h3>
            <h4>2.1 Definitioner:</h4>
            <p>Termen ”Avtalet”, se ”Acceptans” under 1.2 för definition av Avtalet.</p>
            <p>Termen "Kunden" refererar till den juridiska personen som har ingått ett avtal med TQM och har genom detta accepterat Avtalet och dess innehåll fullt ut.</p>
            <p>Termen “Parter” utgör Kunden och TQM Sweden AB gemensamt.</p>
            <p>Termen “Tjänsterna” utgör de tjänster som TQM Sweden AB tillhandahåller och som Kunden nyttjar genom avtalsförhållandet. Tjänsterna innefattar huvudsakligen kvalitetsstyrning, HACCP-system, leverantörshantering, Egenkontroll, Dokumentstyrning, filhantering, AI-granskning av rutin och risker och samtliga andra tjänster som kunden kan nyttja på Quality Food Control som plattform.</p>
        </FadeInSection>
        <br />
        <br />
        <FadeInSection>
            <h3>3. Avtalstid</h3>
            <p>Avtalet löper i enlighet med din prisplan som Kunden har valt eller i enlighet med den offert som TQM och Kunden har enats om.</p>
        </FadeInSection>
        <br />
        <br />
        <FadeInSection>
            <h3>4. Användningsvillkor</h3>
            <h4>4.1 Åtkomst och Användning:</h4>
            <p>Villkor och begränsningar gäller för åtkomst och användning av tjänsten, och Kunden måste följa dessa för att behålla åtkomst till tjänsten.</p>
            <h4>4.2 Spårning och Analysverktyg:</h4>
            <p>Kunden godkänner nödvändig spårning via Google Analytics i syfte att TQM ska kunna hantera volymer samt säkerställa produktens funktionalitet.</p>
            <h4>4.3 Användaruppförande:</h4>
            <p>Kunden förbinder sig att använda tjänsterna i enlighet med gällande lagar och föreskrifter och i enlighet med de rättigheter och skyldigheter som följer av Avtalet. Kunden är fullt ansvarig för den data som Kunden och dennes användare producerar och behandlar med hjälp av tjänsterna. Kunden är ansvarig för brott mot Avtalet som orsakas av Kundens användare. Användare till kunden är exempelvis, men inte uteslutande, anställda, representanter, ombud och konsulter.</p>
            <p>Avtalet ger Kunden en icke-exklusiv, icke överförbar rätt till åtkomst och nyttjande av de specificerade Tjänsterna, i enlighet med villkoren i detta Avtal. Kunden erhåller inte några övriga rättigheter till programvaran eller andra element som används av TQM för att tillhandahålla Tjänsterna.</p>
            <p>Kunden försäkrar om att endast överföra de personuppgifter som Kunden måste för att kunna använda Tjänsterna. Kunden ansvarar för att inte överföra några personuppgifter som inte är förenliga med GDPR eller som Kunden behöver föra in för att nyttja tjänsterna. Gör Kunden ovanstående är Kunden införstådd i att TQM inte är att betrakta som Personuppgiftsbiträde eller Personuppgiftsansvarig.</p>
            <p>Kunden är medveten om att TQM har ett supportkonto, i syfte att bistå med stöd och hjälp till Kunden. TQM har möjligheten via detta att få full insyn i Kundens konto och det innehåll som finns däri. TQM har även möjligheten att göra ändringar via nämnda konto, men gör så enbart på instruktion av Kunden. Kunden kan stänga av supportkontot i sina inställningar, men då upphör även möjligheten för TQM att ge support via detta konto.</p>
        </FadeInSection>
        <br />
        <br />
        <FadeInSection>
            <h3>5. Betalningsvillkor</h3>
            <h4>5.1 Priser:</h4>
            <p>Alla priser för TQMs tjänster framgår av Kundens prisplan och är gällande mellan TQM och Kunden, förutsatt att parterna inte har avtalat om någonting annat.</p>
            <h4>5.1.1 Betalningsvillkor:</h4>
            <p>Varje betalning i enlighet med detta avtal ska erläggas 30 dagar efter fakturadatum. Avgiften för Tjänsten kommer att faktureras i förskott i början av varje Betalningsperiod. Vid försenad betalning har TQM rätt att ta ut ränta på de försenade betalningarna, enligt räntelagen (1975: 365), räknat från förfallodagen tills betalning sker. Dessutom kan TQM, efter att ha skickat en påminnelse till Kunden, stänga av Kundens tillgång till Tjänsterna tills TQM har erhållit alla utestående belopp.</p>
            <h4>5.1.2 Övrigt:</h4>
            <p>Alla priser är exklusive moms och eventuella andra skatter och avgifter, om inte annat anges.</p>
            <h4>5.2 Ångerrätt:</h4>
            <p>TQM erbjuder ingen ångerrätt efter att köpet har genomförts eller efter att ett konto har registrerats.</p>
            <h4>5.3 Uppsägning av avtal:</h4>
            <p>Endera Part har rätt att vid vilken tidpunkt som helst säga upp Avtalet mellan TQM och Kunden. Kunden har rätt till att nyttja sitt abonnemang under tiden som Avtalet gäller och har en skyldighet att betala under hela avtalstiden oaktat en uppsägning.</p>
            <p>Avtal som inte löper under viss tid har en uppsägningstid om en kalendermånad. Uppsägning ska ske skriftligen.</p>
            <p>Avtal som löper på bestämd tid ska sägas upp skriftligen senast en kalendermånad före avtalets utgång; meddelas inte sådan uppsägning förnyas avtalet med samma bindningstid som avtalet ursprungligen hade.</p>
            <h4>5.4 Prisändringar:</h4>
            <p>TQM förbehåller sig rätten att justera priserna för våra tjänster en gång per år. Eventuella prisändringar kommer att baseras på faktorer som inflation, konsumentprisindex eller andra kostnadsökningar som TQM har.</p>
            <p>Kunden kommer att meddelas om eventuella prisändringar i förväg, minst 30 dagar innan prisändringen träder i kraft.</p>
            <p>Har Kunden ett Avtal som är bundet under viss tid gäller det överenskomna priset för hela den perioden som Avtalet gäller och är ej föremål för sådan höjning.</p>
        </FadeInSection>
        <br />
        <br />
        <FadeInSection>
            <h3>6. Dataskydd och Sekretess</h3>
            <h4>6.1 Dataskydd:</h4>
            <p>TQM säkerställer att behandla personuppgifter i enlighet med GDPR och andra tillämpliga lagar och regler.</p>
            <h4>6.2 Integritetspolicy:</h4>
            <p>TQMs integritetspolicy finns tillgänglig på vår webbplats och beskriver hur vi hanterar och skyddar användarnas personuppgifter.</p>
        </FadeInSection>
        <br />
        <br />
        <FadeInSection>
            <h3>7. Immateriella Rättigheter</h3>
            <h4>7.1 Äganderätt:</h4>
            <p>Alla immateriella rättigheter såsom, men inte uteslutande, patent, varumärken, källkod och upphovsrätt, ägs av TQM Sweden AB.</p>
            <h4>7.1.1</h4>
            <p>Kunden får rätten att under en period, som gäller under avtalstiden, att nyttja TQMs lösning. Kunden är införstådd i och accepterar att alla immateriella rättigheter till SaaS-lösningen ägs av och tillhör TQM. Kunden har inte rätt till någon mjukvara eller andra prenumerationstjänster än de som framgår av detta avtal och av den tjänst som Kunden väljer i enlighet med sin prenumerationsplan.</p>
            <p>TQM har rätt att nyttja och dela kunders information och uppgifter i syfte att tillhandahålla tjänsterna, vilket innebär exempelvis, men inte uteslutande, delning av allmänna certifikat inom ramen för den SaaS-lösning TQM tillhandahåller. TQM delar aldrig företagskänslig information och agerar alltid i enlighet med gällande lagar och regelverk. All information kunden tillhandahåller ägs av kunden.</p>
            <p>Skulle TQM anklagas för att begå ett intrång i tredje mans immateriella rättigheter kommer TQM försvara kunden mot en sådan anklagelse på TQMs bekostnad. Det åligger Kunden att informera TQM om en sådan anklagelse inom 48 timmar från det att Kunden fått denna information från tredje man. Vidare ska Kunden hjälpa TQM, med information och liknande, mot en sådan anklagelse i rimlig omfattning utan att begära ersättning.</p>
        </FadeInSection>
        <br />
        <br />
        <FadeInSection>
            <h3>8. Ansvarsbegränsning och Garantier</h3>
            <h4>8.1 Ansvarsbegränsning:</h4>
            <p>Kunden har rätt att kräva ersättning för dokumenterad direkt förlust som Kunden lidit till följd av TQMs brott mot Avtalet. TQM ansvarar inte för indirekta skador eller följdskador såsom utebliven vinst, förluster orsakade av fördröjd start eller avbrott i produktionen, eller förluster till följd av förlorade data eller anspråk från tredje part. Endera parts skadeståndsskyldighet uppgår till högst det belopp som Kunden har betalat till TQM under en period om tolv månader exklusive mervärdesskatt.</p>
            <p>Ersättning för skada ska bevisas av den part som gör gällande att skada har inträffat.</p>
        </FadeInSection>
        <br />
        <br />
        <FadeInSection>
            <h3>9. Force Majeure</h3>
            <p>Om fullgörandet av Avtalet blir omöjligt eller avsevärt mer komplicerat på grund av force majeure, av en omständighet som Parten inte kunde kontrollera och som Parten inte rimligen kunde förväntat sig och vars effekter Parten inte rimligen kunde ha undvikit eller övervunnit, ska en sådan Part tillåtas att fördröja fullgörandet av åtagandet utan påföljd; under förutsättning att den Parten utan dröjsmål skriftligen informerar den andra Parten. Force Majeure ska anses omfatta krig, krigshandling, myndighetsåtgärd, ny eller ändrad lagstiftning, en arbetsmarknadskonflikt, sammanbrott av telekommunikation och andra liknande omständigheter.</p>
        </FadeInSection>
        <br />
        <br />
        <FadeInSection>
            <h3>10. Övrigt</h3>
            <h4>10.1 Ändringar av Avtalet:</h4>
            <p>TQM förbehåller sig rätten att ändra detta avtal när som helst, och det är Kundens ansvar att regelbundet kontrollera Avtalets förändringar.</p>
            <h4>10.2 Användning av Kundens namn och logotyp:</h4>
            <p>TQM har rätt att använda Kundens namn och logotyp i sina försäljningspresentationer, erbjudanden och på TQMs webbplatser.</p>
            <h4>10.3</h4>
            <p>TQM kan helt eller delvis överlåta sina rättigheter och/eller skyldigheter enligt detta Avtal så länge som en sådan överföring inte väsentligen försvårar uppfyllandet av Avtalet. TQM ska informera Kunden inom rimlig tid efter en sådan överföring.</p>
            <h4>10.4</h4>
            <p>Kunden har inte rätt att överlåta sina rättigheter eller skyldigheter enligt Avtalet utan att ett skriftligt tillstånd erhållits från TQM. För det fall TQM tillåter en sådan överlåtelse har TQM rätt att debitera för kostnader som en sådan överlåtelse medför.</p>
        </FadeInSection>
        <br />
        <br />
        <FadeInSection>
            <h3>11. Sekretess</h3>
            <h4>11.1 Konfidentiell Information:</h4>
            <p>Konfidentiell Information ska i detta Avtal avse all information, oaktat om den är muntlig eller skriftlig, i elektronisk eller någon annan form.</p>
            <h4>11.2</h4>
            <p>Såväl TQM som Kunden ska behandla all Konfidentiell Information från den andra parten som konfidentiell. Parterna i Avtalet får inte använda sådan Konfidentiell Information med undantag för vad som anges häri och ska vidta rimliga ansträngningar för att inte avslöja sådan Konfidentiell Information för någon tredje part.</p>
            <p>Varje part samtycker till att de kommer att göra konfidentiell Information tillgänglig endast vid behov (”need-to-know”) och endast för dess anställda, eller andra som har ett skäligt behov av att känna till informationen. Vardera parten ska omedelbart underrätta den andra parten om faktiskt eller misstänkt missbruk eller obehörig röjande av sådan annan Parts Konfidentiella Information ägt rum.</p>
            <h4>11.3</h4>
            <p>Oaktat det ovanstående ska varken TQM eller Kund ha något ansvar gentemot motparten om konfidentiell information har röjts, förutsatt att sådan information: (i) var allmänt känd vid utlämnandet eller var allmänt känt utan att utlämnande Part orsakat detta; (ii) var känd för den mottagande Parten utan begränsningar vid tidpunkten för utlämnandet; (iii) utlämnades med föregående godkännande från den röjande Parten; (iv) utvecklades av den mottagande parten oberoende och utan användning av den andra partens konfidentiella Information; eller (v) måste offentliggöras på grund av tvingande lagstiftning, eller enligt dom eller beslut från domstol, administrativ myndighet eller annat statligt organ.</p>
        </FadeInSection>
        <br />
        <br />
        <FadeInSection>
            <h3>12. Lagval</h3>
            <p>Detta Avtal styrs av och tolkas i enlighet med svensk lag.</p>
            <h4>12.1 Tvistlösning:</h4>
            <p>Tvister som uppstår i samband med detta avtal ska i första hand lösas genom förhandlingar och om en överenskommelse inte kan nås, ska tvisten lösas uteslutande i Stockholm tingsrätt. Vardera parten har rätt att överklaga ett eventuellt domslut till såväl Svea hovrätt som Högsta domstolen.</p>
            <h4>12.2 Kontaktinformation:</h4>
            <p>För frågor angående detta avtal, vänligen kontakta TQM via <a href="mailto:contact@qualityfoodcontrol.com">contact@qualityfoodcontrol.com</a>.</p>
        </FadeInSection>
        <br />
        <br />
        <FadeInSection>
            <h3>13. Godkännande</h3>
            <p>Kunden har godkänt Avtalet i samband med att denne accepterat att bli kund eller att denne har fått tillgång till tjänsten eller tjänsterna som TQM erbjuder, i enlighet med detta Avtal. Det tidigaste skedet ska anses vara gällande som tidpunkt för Avtalets ingående.</p>
        </FadeInSection>
        <br />
        <br />
      </div>
    </div>
  );
}

export default Terms;